import axios from 'axios';
import { IException, IExceptionDetailWithCount, IExceptionInstancesWithCount } from 'fdot-insights-shared';

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/exceptions`;


export const getException = async (id: string): Promise<IException> => {
    const response = await axios.get(`${baseUrl}/${id}`);
    return response.data;
};

export const getExceptionDetails = async (
    exceptionId: string,
    page: number,
    includeCount: boolean): Promise<IExceptionDetailWithCount> => {
    const response = await axios.get(`${baseUrl}/${exceptionId}/details?includeCount=${includeCount ? 'true' : 'false'}&page=${page}`);
    return response.data;
};


export const getExceptionInstances = async (
    id: string,
    page: number,
    includeCount: boolean): Promise<IExceptionInstancesWithCount> => {
    const response = await axios.get(`${baseUrl}/${id}/instances?includeCount=${includeCount ? 'true' : 'false'}&page=${page}`);
    return response.data;
}