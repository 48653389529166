import { IAvailabilitySummary, IAvailabilityTest, ITestResultSummary } from 'fdot-insights-shared';
import axios from 'axios';
import { logException } from 'fdot-insights-shared/services/LogService';

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/availability`;
const availabilityTestBaseUrl = `${baseUrl}/test`;

export const addTest = async (name: string): Promise<IAvailabilityTest> => {
    const response = await axios.post(availabilityTestBaseUrl, { name });
    return response.data;
};

export const updateTest = async (test: IAvailabilityTest): Promise<IAvailabilityTest> => {
    const response = await axios.patch(`${availabilityTestBaseUrl}/${test._id}`, test);
    return response.data;
};

export const fetchAvailabilityTests = async (): Promise<IAvailabilityTest[]> => {
    const response = await axios.get(availabilityTestBaseUrl);
    return response.data;
};

export const fetchTest = async (id: string): Promise<IAvailabilityTest> => {
    const response = await axios.get(`${availabilityTestBaseUrl}/${id}`);
    return response.data;
};

export const fetchTestResults = async (id: string): Promise<ITestResultSummary> => {
    const response = await axios.get(`${availabilityTestBaseUrl}/${id}/results`);
    return response.data;
}

export const deleteTest = async (id: string): Promise<boolean> => {
    try {
        const response = await axios.delete(`${availabilityTestBaseUrl}/${id}`);
        return response.status === 204;
    }
    catch (error) {
        logException(error);
        return false;
    }
};

export const loadSummaries = async (): Promise<IAvailabilitySummary[]> => {
    const response = await axios.get(`${baseUrl}`);
    return response.data;
}