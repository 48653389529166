import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as arculusApi from '../../apis/ArculusApi';
import {
    IQueryResult,
    IQueryParameters,
} from 'fdot-insights-shared';


type SliceState = {
    apiHistogramData: IQueryResult[];
    isLoading: boolean;
    isModalOpen: boolean;
}

const userSlice = createSlice({
    name: 'api-histogram',
    initialState: {
        apiHistogramData: [],
        isLoading: false,
        isModalOpen: false,
    } as SliceState,
    reducers: {
        updateApiHistogramData: (state: SliceState, action: PayloadAction<IQueryResult[]>) => {
            state.apiHistogramData = action.payload;
            return state;
        },
        updateIsLoading: (state: SliceState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            return state;
        },
        updateIsModalOpen: (state: SliceState, action: PayloadAction<boolean>) => {
            state.isModalOpen = action.payload;
            return state;
        }
    }
});

export const {
    updateApiHistogramData,
    updateIsLoading,
    updateIsModalOpen
} = userSlice.actions;
export default userSlice.reducer;

export const getApiHistogramData = (queryParameters: IQueryParameters) => async (dispatch: any) => {
    dispatch(updateIsLoading(true));
    arculusApi.getMetrics(queryParameters)
        .then((results: any[]) => {
            dispatch(updateApiHistogramData(results));
            dispatch(updateIsLoading(false));
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateIsLoading(false));
        });
}