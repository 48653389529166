import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faSearch,
  faMinusCircle,
  faPlusCircle,
  faThumbsUp,
  faThumbsDown,
  faPlay,
  faPause,
  faPlus,
  faTrash,
  faSave,
  faEdit,
  faArrowLeft,
  faBan,
  faEye,
  faSun,
  faMoon,
  faSort,
  faSortDown,
  faSortUp,
  faArrowDown,
  faArrowUp,
  faCircle,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

export const initializeIcons = () => {
  library.add(faExclamationTriangle);
  library.add(faSearch);
  library.add(faMinusCircle);
  library.add(faPlusCircle);
  library.add(faThumbsUp);
  library.add(faThumbsDown);
  library.add(faPlay);
  library.add(faPause);
  library.add(faPlus);
  library.add(faTrash);
  library.add(faEdit);
  library.add(faSave);
  library.add(faArrowLeft);
  library.add(faBan);
  library.add(faEye);
  library.add(faSun);
  library.add(faMoon);
  library.add(faSort);
  library.add(faSortDown);
  library.add(faSortUp);
  library.add(faArrowUp);
  library.add(faArrowDown);
  library.add(faCircle);
  library.add(faFileExcel);
};
