import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as arculusApi from "../../apis/ArculusApi";
import {
  DurationType,
  TimespanDurationType,
  GroupingOption,
  IApiSubscription,
  IApiUser,
  IApiUserExtended,
  IQueryResult,
  IQueryParameters,
  IArculusApi,
  IApiOperation,
  MetricType,
  ILogEntry,
  ILogQueryParameters,
  ArculusRequestStatus,
} from "fdot-insights-shared";
import { parseISO } from "date-fns";

type SliceState = {
  users: IApiUser[];
  subscriptions: IApiSubscription[];
  apis: IArculusApi[];
  operations: IApiOperation[];
  inventory: IArculusApi[];

  filteredUsers: IApiUser[];
  filteredSubscriptions: IApiSubscription[];
  filteredApis: IArculusApi[];
  filteredOperations: IApiOperation[];

  extendedApiUsers: IApiUserExtended[];
  selectedApiUsers: IApiUserExtended[];
  selectedApiSubscriptions: IApiSubscription[];

  durationType: DurationType;
  timespanDurationType: TimespanDurationType;
  timespanDuration: number;
  startDate: string;
  endDate: string;
  statusCode: number | null;
  responseStatusFilter: ArculusRequestStatus;

  apiMetrics: IQueryResult[];
  logResults: ILogEntry[];

  groupingOptions: GroupingOption[];
  loadingMetrics: boolean;

  showApiColumn: boolean;
  showOperationColumn: boolean;
  showSubscriptionColumn: boolean;
  showUserColumn: boolean;
};

const userSlice = createSlice({
  name: "arculus",
  initialState: {
    users: [],
    subscriptions: [],
    apis: [],
    operations: [],
    inventory: [],

    filteredUsers: [],
    filteredSubscriptions: [],
    filteredApis: [],
    filteredOperations: [],

    extendedApiUsers: [],
    selectedApiUsers: [],
    selectedApiSubscriptions: [],

    durationType: DurationType.Timespan,
    timespanDurationType: TimespanDurationType.Days,
    timespanDuration: 7,
    startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    endDate: new Date().toISOString(),
    statusCode: null,
    responseStatusFilter: ArculusRequestStatus.All,

    apiMetrics: [],
    logResults: [],
    groupingOptions: [GroupingOption.Api, GroupingOption.Operation, GroupingOption.Subscription, GroupingOption.User],
    showApiColumn: true,
    showOperationColumn: true,
    showSubscriptionColumn: false,
    showUserColumn: false,

    loadingMetrics: false,
  } as SliceState,
  reducers: {
    updateUsers: (state: SliceState, action: PayloadAction<IApiUser[]>) => {
      state.users = action.payload;
      return state;
    },
    updateExtendedUsers: (state: SliceState, action: PayloadAction<IApiUserExtended[]>) => {
      state.extendedApiUsers = action.payload;
      return state;
    },
    updateSubscriptions: (state: SliceState, action: PayloadAction<IApiSubscription[]>) => {
      state.subscriptions = action.payload;
      return state;
    },
    updateApis: (state: SliceState, action: PayloadAction<IArculusApi[]>) => {
      state.apis = action.payload;
      return state;
    },
    updateOperations: (state: SliceState, action: PayloadAction<IApiOperation[]>) => {
      const existingIds = state.filteredOperations.map((i) => i.name);
      const toAdd = action.payload.filter((i) => existingIds.indexOf(i.name) === -1);
      state.operations = state.filteredOperations.concat(toAdd);
      return state;
    },
    updateFilteredUsers: (state: SliceState, action: PayloadAction<IApiUser[]>) => {
      state.filteredUsers = action.payload;
      return state;
    },
    updateFilteredSubscriptions: (state: SliceState, action: PayloadAction<IApiSubscription[]>) => {
      state.filteredSubscriptions = action.payload;
      return state;
    },
    updateFilteredApis: (state: SliceState, action: PayloadAction<IArculusApi[]>) => {
      state.filteredApis = action.payload;
      return state;
    },
    updateFilteredOperations: (state: SliceState, action: PayloadAction<IApiOperation[]>) => {
      state.filteredOperations = action.payload;
      return state;
    },
    updateApiMetrics: (state: SliceState, action: PayloadAction<IQueryResult[]>) => {
      state.apiMetrics = action.payload;
      return state;
    },
    updateApiLogs: (state: SliceState, action: PayloadAction<ILogEntry[]>) => {
      state.logResults = action.payload;
      return state;
    },
    updateInventory: (state: SliceState, action: PayloadAction<IArculusApi[]>) => {
      state.inventory = action.payload;
      return state;
    },
    updateGroupingOptions: (state: SliceState, action: PayloadAction<GroupingOption[]>) => {
      state.groupingOptions = action.payload;
      return state;
    },
    updateLoadingMetrics: (state: SliceState, action: PayloadAction<boolean>) => {
      state.loadingMetrics = action.payload;
      return state;
    },
    updateShowApiColumn: (state: SliceState, action: PayloadAction<boolean>) => {
      state.showApiColumn = action.payload;
      return state;
    },
    updateShowOperationColumn: (state: SliceState, action: PayloadAction<boolean>) => {
      state.showOperationColumn = action.payload;
      return state;
    },
    updateShowSubscriptionColumn: (state: SliceState, action: PayloadAction<boolean>) => {
      state.showSubscriptionColumn = action.payload;
      return state;
    },
    updateShowUserColumn: (state: SliceState, action: PayloadAction<boolean>) => {
      state.showUserColumn = action.payload;
      return state;
    },
    updateDurationType: (state: SliceState, action: PayloadAction<DurationType>) => {
      state.durationType = action.payload;
      return state;
    },
    updateTimespanDurationType: (state: SliceState, action: PayloadAction<TimespanDurationType>) => {
      state.timespanDurationType = action.payload;
      return state;
    },
    updateTimespanDuration: (state: SliceState, action: PayloadAction<number>) => {
      state.timespanDuration = action.payload;
      return state;
    },
    updateStartDate: (state: SliceState, action: PayloadAction<string>) => {
      state.startDate = action.payload;
      return state;
    },
    updateEndDate: (state: SliceState, action: PayloadAction<string>) => {
      state.endDate = action.payload;
      return state;
    },
    updateStatusCode: (state: SliceState, action: PayloadAction<number | null>) => {
      state.statusCode = action.payload;
      return state;
    },
    updateResponseStatusCode: (state: SliceState, action: PayloadAction<ArculusRequestStatus>) => {
      state.responseStatusFilter = action.payload;
      return state;
    },
    updateSelectedUsers: (state: SliceState, action: PayloadAction<IApiUserExtended[]>) => {
      state.selectedApiUsers = action.payload;
      return state;
    },
    updateSelectedSubscriptions: (state: SliceState, action: PayloadAction<IApiSubscription[]>) => {
      state.selectedApiSubscriptions = action.payload;
      return state;
    },
  },
});

export const {
  updateUsers,
  updateExtendedUsers,
  updateSubscriptions,
  updateApis,
  updateOperations,
  updateFilteredUsers,
  updateFilteredSubscriptions,
  updateFilteredApis,
  updateFilteredOperations,
  updateApiMetrics,
  updateApiLogs,
  updateInventory,
  updateGroupingOptions,
  updateLoadingMetrics,
  updateShowApiColumn,
  updateShowOperationColumn,
  updateShowSubscriptionColumn,
  updateShowUserColumn,
  updateDurationType,
  updateTimespanDuration,
  updateTimespanDurationType,
  updateStartDate,
  updateEndDate,
  updateStatusCode,
  updateResponseStatusCode,
  updateSelectedUsers,
  updateSelectedSubscriptions,
} = userSlice.actions;
export default userSlice.reducer;

export const fetchUsersAndSubscriptions = () => async (dispatch: any) => {
  const usersPromise = arculusApi.getUsers();
  const subscriptionPromise = arculusApi.getSubscriptions();

  Promise.all([usersPromise, subscriptionPromise])
    .then(([users, subscriptions]) => {
      dispatch(updateUsers(users));
      dispatch(updateSubscriptions(subscriptions));

      const extendedApiUsers: IApiUserExtended[] = users
        ? users.map((apiUser: IApiUser) => {
            const suffix = `users/${apiUser.name}`;
            const extendedApiUser = JSON.parse(JSON.stringify(apiUser));
            const matches = subscriptions.filter((s) => s.properties.ownerId && s.properties.ownerId.indexOf(suffix) !== -1);
            if (matches.length > 0) {
              debugger;
            }
            extendedApiUser.subscriptions = matches;
            return extendedApiUser;
          })
        : [];
      dispatch(updateExtendedUsers(extendedApiUsers));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const fetchSubscriptions = () => async (dispatch: any) => {
  arculusApi
    .getSubscriptions()
    .then((subscriptions: IApiSubscription[]) => {})
    .catch((error: any) => {
      console.log(error);
    });
};
export const fetchInventory = () => async (dispatch: any) => {
  dispatch(updateLoadingMetrics(true));
  arculusApi
    .getInventory()
    .then((apis: IArculusApi[]) => {
      dispatch(updateInventory(apis));
      dispatch(updateLoadingMetrics(false));
    })
    .catch((error: any) => {
      console.log(error);
      dispatch(updateLoadingMetrics(false));
    });
};

export const fetchApis = () => async (dispatch: any) => {
  arculusApi
    .getApis()
    .then((apis: IArculusApi[]) => {
      dispatch(updateApis(apis));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const fetchOperationsForApis = (filteredApis: IArculusApi[], filteredOperations: IApiOperation[]) => async (dispatch: any) => {
  const apiIds = filteredApis.map((i) => i.name);
  const apiIdsToFetch = apiIds.filter((apiId) => !filteredOperations.some((operation) => operation.api.name === apiId));
  if (apiIdsToFetch.length === 0) {
    return;
  }

  const promises = apiIdsToFetch.map((i) => arculusApi.getOperationsByApiId(i));
  Promise.all(promises)
    .then((operationsArray: IApiOperation[][]) => {
      let newOperationsList: IApiOperation[] = [];
      operationsArray.forEach((operations: IApiOperation[]) => {
        newOperationsList = newOperationsList.concat(operations);
      });
      dispatch(updateOperations(newOperationsList));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getMetrics = (queryParameters: IQueryParameters) => async (dispatch: any) => {
  dispatch(updateLoadingMetrics(true));
  arculusApi
    .getMetrics(queryParameters)
    .then((results: any[]) => {
      dispatch(updateApiMetrics(results));
      dispatch(updateLoadingMetrics(false));

      dispatch(updateShowApiColumn(queryParameters.groupings.indexOf(GroupingOption.Api) !== -1));
      dispatch(updateShowOperationColumn(queryParameters.groupings.indexOf(GroupingOption.Operation) !== -1));
      dispatch(updateShowSubscriptionColumn(queryParameters.groupings.indexOf(GroupingOption.Subscription) !== -1));
      dispatch(updateShowUserColumn(queryParameters.groupings.indexOf(GroupingOption.User) !== -1));
    })
    .catch((error) => {
      console.log(error);
      dispatch(updateLoadingMetrics(false));
    });
};

export const getLogs = (queryParameters: ILogQueryParameters) => async (dispatch: any) => {
  dispatch(updateLoadingMetrics(true));
  arculusApi
    .getLogs(queryParameters)
    .then((results: ILogEntry[]) => {
      dispatch(updateApiLogs(results));
      dispatch(updateLoadingMetrics(false));

      dispatch(updateShowApiColumn(queryParameters.groupings.indexOf(GroupingOption.Api) !== -1));
      dispatch(updateShowOperationColumn(queryParameters.groupings.indexOf(GroupingOption.Operation) !== -1));
      dispatch(updateShowSubscriptionColumn(queryParameters.groupings.indexOf(GroupingOption.Subscription) !== -1));
      dispatch(updateShowUserColumn(queryParameters.groupings.indexOf(GroupingOption.User) !== -1));
    })
    .catch((error) => {
      console.log(error);
      dispatch(updateLoadingMetrics(false));
    });
};

export const createQueryParameters = (state: SliceState, metricType: MetricType | null): IQueryParameters => {
  return {
    metricType,
    durationType: state.durationType,
    timespanDurationType: state.timespanDurationType,
    timespanDuration: state.timespanDuration,
    startDate: parseISO(state.startDate),
    endDate: parseISO(state.endDate),
    filters: {
      apiIds: state.filteredApis.map((i) => i.name),
      operationIds: state.filteredOperations.map((i) => i.name),
      subscriptionIds: state.filteredSubscriptions.map((i) => i.name),
      userIds: state.filteredUsers.map((i) => i.name),
    },
    groupings: state.groupingOptions,
    isHistogramQuery: false,
  };
};

export const createLogQueryParameters = (state: SliceState, metricType: MetricType | null): ILogQueryParameters => {
  return {
    metricType,
    durationType: state.durationType,
    timespanDurationType: state.timespanDurationType,
    timespanDuration: state.timespanDuration,
    startDate: parseISO(state.startDate),
    endDate: parseISO(state.endDate),
    statusCode: state.statusCode,
    responseStatusFilter: state.responseStatusFilter,
    filters: {
      apiIds: state.filteredApis.map((i) => i.name),
      operationIds: state.filteredOperations.map((i) => i.name),
      subscriptionIds: state.filteredSubscriptions.map((i) => i.name),
      userIds: state.filteredUsers.map((i) => i.name),
    },
    groupings: state.groupingOptions,
    isHistogramQuery: false,
  };
};
