import axios from 'axios';
import { IApplication, Level, ISearchCriteria, ISearchResult } from 'fdot-insights-shared';

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/search`;

export const getApplicationById  = async (id: string): Promise<IApplication> => {
    const response = await axios.get(`${baseUrl}/application/${id}`);
    return response.data;
};

export const findApplicationMatches = async (searchTerm: string, levels: Level[]): Promise<IApplication[]> => {
    const response = await axios.post(`${baseUrl}/application`, { searchTerm, levels });
    return response.data;
};

export const findUserMatches = async (searchTerm: string): Promise<string[]> => {
    const response = await axios.post(`${baseUrl}/user`, { searchTerm });
    return response.data;
};

export const search = async (searchCriteria: ISearchCriteria): Promise<ISearchResult> => {
    const response = await axios.post(baseUrl, searchCriteria);
    return response.data;
}