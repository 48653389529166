import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from "react-router-dom";
import RouteWrapper from './components/routing/RouteWrapper';
import { createBrowserHistory } from 'history';
import AxiosInit from './components/AxiosInit';

const App: React.FC = (props) => {

  // This is required for React-Router - useNavigate hook uses history under the hood
  createBrowserHistory();

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
      >
      </ToastContainer>
      <BrowserRouter >
        <AxiosInit></AxiosInit>
        <RouteWrapper></RouteWrapper>
      </BrowserRouter>
    </>
  );
}

export default App;
