import { IFdotInsightsApp, IApplicationInsight } from 'fdot-insights-shared';
import axios from 'axios';
import { logException } from 'fdot-insights-shared/services/LogService';

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/applications`;

export const addApp = async (name: string): Promise<IFdotInsightsApp> => {
    const response = await axios.post(baseUrl, { name });
    return response.data;
};

export const updateApp = async (app: IFdotInsightsApp): Promise<IFdotInsightsApp> => {
    const response = await axios.patch(`${baseUrl}/${app._id}`, app);
    return response.data;
};

export const addAppInsight = async (appId: string, appInsight: IApplicationInsight): Promise<IFdotInsightsApp> => {
    const response = await axios.post(`${baseUrl}/${appId}/appInsights`, appInsight);
    return response.data;
};

export const updateAppInsight = async (appId: string, appInsight: IApplicationInsight): Promise<IFdotInsightsApp> => {
    const response = await axios.post(`${baseUrl}/${appId}/appInsights/${appInsight._id}`, appInsight);
    return response.data;
};

const createTimeString = (data: any) => {
    const hours = new Date(data).getHours();
    if (hours === 12) {
        return '12 pm'
    }
    if (hours === 24 || hours === 0) {
        return '12 am'
    }
    return hours > 12
        ? `${hours - 12} pm`
        : `${hours} am`;
}

export const getDataFromAppInsights = async (appId: string, appInsightAppId: string): Promise<any> => {
    const response = await axios.get(`${baseUrl}/${appId}/appInsights/${appInsightAppId}`);
    const data = response.data;

    data.requestByHourData = data.requestsByHour.value.segments.map((s: any, i: number) => {
        return {
            requests: s['requests/count'].sum,
            hour: new Date(s.start).getHours(),
            time: createTimeString(s.start),
        }
    });

    data.usersByHourData = data.usersByHour.value.segments.map((s: any, i: number) => {
        return {
            users: s['users/count'].unique,
            hour: new Date(s.start).getHours(),
            time: createTimeString(s.start),
        }
    });
    data.appId = appId;
    data.appInsightAppId = appInsightAppId;

    return data;
};

export const getSummaryDataFromAppInsights = async (appId: string, appInsightAppId: string): Promise<any> => {
    const response = await axios.get(`${baseUrl}/${appId}/appInsights/${appInsightAppId}/summary`);
    return response.data;
};

export const fetchApps = async (): Promise<IFdotInsightsApp[]> => {
    const response = await axios.get(baseUrl);
    return response.data;
};

export const fetchApp = async (id: string): Promise<IFdotInsightsApp> => {
    const response = await axios.get(`${baseUrl}/${id}`);
    return response.data;
};

export const deleteApp = async (id: string): Promise<boolean> => {
    try {
        const response = await axios.delete(`${baseUrl}/${id}`);
        return response.status === 204;
    }
    catch (error) {
        logException(error);
        return false;
    }
};

export const deleteAppInsight = async (appId: string, id: string): Promise<IFdotInsightsApp> => {
    const response = await axios.delete(`${baseUrl}/${appId}/appInsights/${id}`);
    return response.data;
};