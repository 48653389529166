import { MetricType } from "fdot-insights-shared";
import React, { Suspense } from "react";
import Loading from "react-loading";
import { Routes, Route } from "react-router-dom";

const Home = React.lazy(() => import("../Home"));
const NotAuthorized = React.lazy(() => import("../NotAuthorized"));
const LoginFailure = React.lazy(() => import("../LoginFailure"));
const NoMatch = React.lazy(() => import("../NoMatch"));
const Staff = React.lazy(() => import("../staff/Staff"));
const Impersonate = React.lazy(() => import("../Impersonate"));
const MainException = React.lazy(() => import("../exceptions/MainException"));
const ExceptionDetailInstances = React.lazy(
  () => import("../exceptions/ExceptionDetailInstances")
);
const ExceptionNotifications = React.lazy(
  () => import("../exceptions/notifications/ExceptionNotifications")
);
const Search = React.lazy(() => import("../exceptions/search/Search"));
const LiveTail = React.lazy(() => import("../liveTail/LiveTail"));
const Applications = React.lazy(() => import("../apps/Applications"));
const AppMain = React.lazy(() => import("../apps/AppMain"));
const AppInsightsDashboard = React.lazy(
  () => import("../apps/AppInsightsDashboard")
);
const AppInsights = React.lazy(
  () => import("../appInsights/ApplicationInsights")
);
const ApplicationWrapper = React.lazy(
  () => import("../apps/ApplicationWrapper")
);
const MainMetricPage = React.lazy(
  () => import("../arculus/Shared/MainMetricPage")
);
const ArculusLogs = React.lazy(() => import("../arculus/Shared/ArculusLogs"));
const ArculusInventory = React.lazy(() => import("../arculus/Shared/ArculusInventory"));
const ArculusUsers = React.lazy(() => import("../arculus/Shared/ArculusUsers"));
const Availability = React.lazy(
  () => import("../availability/AvailabilityTestDashboard")
);
const AvailabilityTests = React.lazy(
  () => import("./../availability/AvailabilityTests")
);
const AvailabilityTestWrapper = React.lazy(
  () => import("./../availability/AvailabilityTestWrapper")
);
const AvailabilityTestResult = React.lazy(
  () => import("./../availability/AvailabilityTestResult")
);

const AuthenticatedRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loading></Loading>}>
      <Routes>
        <Route path="/" element={<Search />} />

        <Route path="/NotAuthorized" element={<NotAuthorized />} />
        <Route path="/LoginFailure" element={<LoginFailure />} />

        <Route path="/Staff" element={<Staff />} />
        <Route path="/Impersonate" element={<Impersonate />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Applications" element={<Applications />} />
        <Route path="/Applications/:appId" element={<ApplicationWrapper />}>
          <Route path="" element={<AppMain />} key="Main" />
          <Route
            path=":viewType"
            element={<AppMain />}
            key="MainWithViewType"
          />
          <Route
            path="AppInsights/:appInsightAppId"
            element={<AppInsightsDashboard />}
            key="appInsightDashboard"
          />
        </Route>

        <Route path="/Search">
          <Route path=":appId/:level" element={<Search />} />
          <Route path="" element={<Search />} />
        </Route>

        <Route path="/LiveTail" element={<LiveTail />} />
        <Route path="/Exception/:id" element={<MainException />} />
        <Route
          path="/Exception/:exceptionId/Detail/:exceptionDetailId"
          element={<ExceptionDetailInstances />}
        />

        <Route path="/ExceptionNotification">
          <Route path=":appId/:level" element={<ExceptionNotifications />} />
          <Route path="" element={<ExceptionNotifications />} />
        </Route>

        <Route
          path="/ExceptionNotification/:appName?/:level?"
          element={<ExceptionNotifications />}
        />

        <Route
          path="/Arculus/Usage"
          element={
            <MainMetricPage metricType={MetricType.Count}></MainMetricPage>
          }
        />
        <Route
          path="/Arculus/Response"
          element={
            <MainMetricPage
              metricType={MetricType.ResponseTime}
            ></MainMetricPage>
          }
        />
        <Route
          path="/Arculus/Bandwidth"
          element={
            <MainMetricPage metricType={MetricType.Bandwidth}></MainMetricPage>
          }
        />
        <Route
          path="/Arculus/Error"
          element={
            <MainMetricPage metricType={MetricType.Errors}></MainMetricPage>
          }
        />
        <Route path="/Arculus/Logs" element={<ArculusLogs></ArculusLogs>} />
        <Route path="/Arculus/Inventory" element={<ArculusInventory></ArculusInventory>} />
        <Route path="/Arculus/Users" element={<ArculusUsers></ArculusUsers>} />

        <Route
          path="/AppInsights"
          element={<AppInsights></AppInsights>}
        ></Route>

        <Route
          path="/Availability"
          element={<Availability></Availability>}
        ></Route>
        <Route
          path="/Availability/Tests/:id/Results"
          element={<AvailabilityTestResult></AvailabilityTestResult>}
        ></Route>
        <Route
          path="/Availability/Tests/:id"
          element={<AvailabilityTestWrapper></AvailabilityTestWrapper>}
        ></Route>
        <Route
          path="/Availability/Tests"
          element={<AvailabilityTests></AvailabilityTests>}
        ></Route>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
};

export default AuthenticatedRoutes;
