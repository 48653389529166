"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityStepType = void 0;
var AvailabilityStepType;
(function (AvailabilityStepType) {
    AvailabilityStepType["LoadPage"] = "Load Page";
    AvailabilityStepType["ClickLink"] = "Click Link";
    AvailabilityStepType["ClickButton"] = "Click Button";
    AvailabilityStepType["EnterTextInput"] = "Enter Text Input";
    AvailabilityStepType["VerifyExistenceOfElement"] = "Verify Existence of Element";
    AvailabilityStepType["Delay"] = "Delay";
})(AvailabilityStepType = exports.AvailabilityStepType || (exports.AvailabilityStepType = {}));
