import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAvailabilitySummary, IAvailabilityTest, ITestResultSummary } from 'fdot-insights-shared';
import * as availabilityApi from '../../apis/AvailabilityApi';

type SliceState = {
    availabilityTests: IAvailabilityTest[],
    selectedTest: IAvailabilityTest | null,
    selectedTestResults: ITestResultSummary | null,
    isLoading: boolean,
    summaries: IAvailabilitySummary[]
}

const availabilitySlice = createSlice({
    name: 'availability',
    initialState: {
        availabilityTests: [],
        selectedTest: null,
        selectedTestResults: null,
        isLoading: false,
        summaries: [],
    } as SliceState,
    reducers: {
        setSelectedTestInternal: (state: SliceState, action: PayloadAction<IAvailabilityTest | null>) => {
            state.selectedTest = action.payload;
            return state;
        },
        setSelectedTestResults: (state: SliceState, action: PayloadAction<ITestResultSummary | null>) => {
            state.selectedTestResults = action.payload;
            return state;
        },
        addNewTestInternal: (state: SliceState, action: PayloadAction<IAvailabilityTest>) => {
            const index = state.availabilityTests.findIndex(i => i._id === action.payload._id);
            if (index === -1) {
                state.availabilityTests.push(action.payload);
            }
            else {
                state.availabilityTests[index] = action.payload;
            }
            return state;
        },
        updateTestInternal: (state: SliceState, action: PayloadAction<IAvailabilityTest>) => {
            const index = state.availabilityTests.findIndex(i => i._id === action.payload._id);
            state.availabilityTests[index] = action.payload;
            if (state.selectedTest?._id === action.payload._id) {
                state.selectedTest = action.payload;
            }
            return state;
        },
        setTestsInternal: (state: SliceState, action: PayloadAction<IAvailabilityTest[]>) => {
            state.availabilityTests = action.payload;
            return state;
        },
        updateIsLoadingInternal: (state: SliceState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            return state;
        },
        removeTestInternal: (state: SliceState, action: PayloadAction<string>) => {
            state.availabilityTests = state.availabilityTests.filter(i => i._id !== action.payload);
            return state;
        },
        updateSummaries: (state: SliceState, action: PayloadAction<IAvailabilitySummary[]>) => {
            state.summaries = action.payload;
            return state;
        }
    }
});

export default availabilitySlice.reducer;

export const addAvailabilityTest = (
    name: string,
    successCallback: (test: IAvailabilityTest) => void,
    failureCallback: () => void) => async (dispatch: any) => {
        dispatch(availabilitySlice.actions.updateIsLoadingInternal(true));
        availabilityApi.addTest(name)
            .then(
                (test: IAvailabilityTest) => {
                    dispatch(availabilitySlice.actions.addNewTestInternal(test));
                    dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
                    successCallback(test);
                })
            .catch((error: any) => {
                console.log(error);
                dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
                failureCallback();
            });
    };

export const updateAvailabilityTest = (
    availabilityTest: IAvailabilityTest,
    successCallback: (test: IAvailabilityTest) => void,
    failureCallback: () => void) => async (dispatch: any) => {
        dispatch(availabilitySlice.actions.updateIsLoadingInternal(true));
        availabilityApi.updateTest(availabilityTest)
            .then(
                (test: IAvailabilityTest) => {
                    dispatch(availabilitySlice.actions.updateTestInternal(test));
                    dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
                    successCallback(test);
                })
            .catch((error: any) => {
                console.log(error);
                dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
                failureCallback();
            });
    };

export const fetchAvailabilityTests = () => async (dispatch: any) => {
    dispatch(availabilitySlice.actions.updateIsLoadingInternal(true));
    availabilityApi.fetchAvailabilityTests()
        .then(
            (tests: IAvailabilityTest[]) => {
                dispatch(availabilitySlice.actions.setTestsInternal(tests));
                dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
        });
};

export const fetchAvailabilityTest = (id: string) => async (dispatch: any) => {
    dispatch(availabilitySlice.actions.updateIsLoadingInternal(true));
    dispatch(availabilitySlice.actions.setSelectedTestInternal(null));
    availabilityApi.fetchTest(id)
        .then(
            (test: IAvailabilityTest | null) => {
                dispatch(availabilitySlice.actions.setSelectedTestInternal(test));
                if (test !== null) {
                    dispatch(availabilitySlice.actions.addNewTestInternal(test));
                }
                dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
        });
};

export const fetchAvailabilityTestResults = (id: string) => async (dispatch: any) => {
    dispatch(availabilitySlice.actions.updateIsLoadingInternal(true));
    dispatch(availabilitySlice.actions.setSelectedTestResults(null));
    availabilityApi.fetchTestResults(id)
        .then(
            (results: ITestResultSummary | null) => {
                dispatch(availabilitySlice.actions.setSelectedTestResults(results));
                dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
        });
};

export const deleteAvailabilityTest = (id: string) => async (dispatch: any) => {
    dispatch(availabilitySlice.actions.updateIsLoadingInternal(true));
    availabilityApi.deleteTest(id)
        .then(
            (successful: boolean) => {
                if (successful) {
                    dispatch(availabilitySlice.actions.removeTestInternal(id));
                    dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
                }
                else {
                    dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
                }
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
        });
}


export const loadSummaries = () => async (dispatch: any) => {
    dispatch(availabilitySlice.actions.updateIsLoadingInternal(true));
    availabilityApi.loadSummaries().then(
        (summaries: IAvailabilitySummary[]) => {
            if (summaries) {
                dispatch(availabilitySlice.actions.updateSummaries(summaries));
                dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
            }
            else {
                dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
            }
        })
        .catch((error: any) => {
            console.log(error);
            dispatch(availabilitySlice.actions.updateIsLoadingInternal(false));
        });
}

export const getAppById = (state: SliceState, id: string | undefined): IAvailabilityTest | undefined => {
    return id
        ? state.availabilityTests.find(i => i._id === id)
        : undefined;
}

