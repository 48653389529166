import axios from 'axios';
import { IUser, IProfile } from 'fdot-insights-shared';

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/user`;
const profileUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/profile`;

export const getUser = async (): Promise<IUser> => {
    const response = await axios.get(baseUrl);
    return response.data;
};

export const impersonate = async (srsId: number): Promise<IUser> => {
    const response = await axios.post(`${baseUrl}/impersonate`, { srsId });
    return response.data;
}

export const updateProfile = async (profile: IProfile): Promise<IProfile> => {
    const response = await axios.post(`${profileUrl}/${profile.srsId}`, profile);
    return response.data;
}

export const fetchProfile = async (srsId: number): Promise<IProfile> => {
    const response = await axios.get(`${profileUrl}/${srsId}`);
    return response.data;
}

export const getUserPhotoUri = async (azureAdOid: string | null | undefined): Promise<string | null> => {
    if (azureAdOid === undefined || azureAdOid === null) {
        return null;
    }
    const response = await axios.get(`${profileUrl}/${azureAdOid}/photo`);
    return response.data;
}