import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AxiosInit: React.FC = () => {

    const navigate = useNavigate();

    // This will ensure axios includes cookies
    axios.defaults.withCredentials = true;

    // IE 11 only
    axios.defaults.headers.get['Pragma'] = 'no-cache';
    axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

    axios.interceptors.response.use(
        (response) => {
            // Do something with response data
            return response;
        }, (error) => {
            // Do something with response error
            if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                console.log(error.response);
                navigate('/NotAuthorized')
            }

            // Throw error again (may be need for some other catch)
            return Promise.reject(error);
        });

    return null;
};

export default AxiosInit;