"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricType = void 0;
var MetricType;
(function (MetricType) {
    MetricType["Count"] = "Count";
    MetricType["ResponseTime"] = "ResponseTime";
    MetricType["Bandwidth"] = "Bandwidth";
    MetricType["Errors"] = "Errors";
})(MetricType = exports.MetricType || (exports.MetricType = {}));
