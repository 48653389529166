import * as userApi from '../../apis/UserApi';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile, Theme } from 'fdot-insights-shared';

type SliceState = {
    theme: Theme;
}

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        theme: Theme.Standard
    } as SliceState,
    reducers: {
        updateTheme: (state: SliceState, action: PayloadAction<Theme>) => {
            state.theme = action.payload;
            return state;
        }
    }
});

export const { updateTheme } = profileSlice.actions;
export default profileSlice.reducer;

export const changeTheme = (srsId: number, theme: Theme) => async (dispatch: any) => {
    userApi.updateProfile({ srsId, theme })
        .then(
            (updatedProfile: IProfile) => {
                dispatch(updateTheme(updatedProfile.theme));
            })
        .catch((error: any) => {
            console.log(error);
        });
}

export const fetchUserProfile = (srsId: number) => async (dispatch: any) => {
    userApi.fetchProfile(srsId)
        .then(
            (profile: IProfile) => {
                dispatch(updateTheme(profile.theme));
            })
        .catch((error: any) => {
            console.log(error);
        });
} 