"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupingOption = void 0;
var GroupingOption;
(function (GroupingOption) {
    GroupingOption["Subscription"] = "ApimSubscriptionId";
    GroupingOption["Api"] = "ApiId";
    GroupingOption["Operation"] = "OperationId";
    GroupingOption["User"] = "UserId";
})(GroupingOption = exports.GroupingOption || (exports.GroupingOption = {}));
