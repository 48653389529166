import React from 'react';
import SafeLink from './common/SafeLink';

const Footer = () => {

    return (
        <div className="container-fluid">
            <div className="row justify-content-md-center">
                <nav className="navbar fixed-bottom navbar-light bg-light">
                    <div className="col text-center">
                        FDOT Insights
                        <br />
                        Report technical problems to the&nbsp;
                        <a href="mailto:FDOT.ServiceDesk@dot.state.fl.us?FDOT Insights" title="Use this link to E-Mail concerns, problems, and/or comments">
                            Service Desk
                        </a>
                        &nbsp;at 1-866-955-4357
                        <br />
                        <SafeLink
                            url="https://www.fdot.gov/agencyresources/webpoliciesandnotices.shtm"
                            linkText="Web Policies and Notices">
                        </SafeLink>
                        &nbsp; - &nbsp;
                        <SafeLink
                            url="https://www.fdot.gov/agencyresources/notices/accessibility.shtm"
                            linkText="Accessibility Statement">
                        </SafeLink>
                    </div>
                </nav >
            </div>
        </div >
    );
}

export default Footer;
