"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Level = void 0;
var Level;
(function (Level) {
    Level["WorkstationOrUnknown"] = "WorkstationOrUnknown";
    Level["UnitTest"] = "UnitTest";
    Level["SystemTest"] = "SystemTest";
    Level["Production"] = "Production";
})(Level = exports.Level || (exports.Level = {}));
