"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialize = exports.logException = exports.log = void 0;
let log = () => { };
exports.log = log;
let logException = () => { };
exports.logException = logException;
const initialize = (logService) => {
    exports.log = logService.log;
    exports.logException = logService.logException;
};
exports.initialize = initialize;
