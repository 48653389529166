import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFdotInsightsApp, IApplicationInsight } from 'fdot-insights-shared';
import * as appApi from '../../apis/AppApi';

type SliceState = {
    apps: IFdotInsightsApp[],
    selectedApp: IFdotInsightsApp | null,
    isLoading: boolean,
    isLoadingAppInsights: boolean,
    isLoadingAppInsightsSummary: boolean,
    appInsightSummaryData: any | null,
    appInsightData: any | null,
    navigateBackToManagePage: boolean,
    noBack: boolean
}

const appSlice = createSlice({
    name: 'newApp',
    initialState: {
        apps: [],
        selectedApp: null,
        isLoading: false,
        isLoadingAppInsights: false,
        isLoadingAppInsightsSummary: false,
        appInsightSummaryData: null,
        appInsightData: null,
        navigateBackToManagePage: false,
        noBack: true
    } as SliceState,
    reducers: {
        setSelectedAppInternal: (state: SliceState, action: PayloadAction<IFdotInsightsApp | null>) => {
            state.selectedApp = action.payload;
            return state;
        },
        addNewAppInternal: (state: SliceState, action: PayloadAction<IFdotInsightsApp>) => {
            const index = state.apps.findIndex(i => i._id === action.payload._id);
            if (index === -1) {
                state.apps.push(action.payload);
            }
            else {
                state.apps[index] = action.payload;
            }
            return state;
        },
        updateAppInternal: (state: SliceState, action: PayloadAction<IFdotInsightsApp>) => {
            const index = state.apps.findIndex(i => i._id === action.payload._id);
            state.apps[index] = action.payload;
            if (state.selectedApp?._id === action.payload._id) {
                state.selectedApp = action.payload;
            }
            return state;
        },
        updateAppInsightDataInternal: (state: SliceState, action: PayloadAction<any>) => {
            state.appInsightData = action.payload;
            return state;
        },
        updateAppInsightSummaryDataInternal: (state: SliceState, action: PayloadAction<any>) => {
            state.appInsightSummaryData = action.payload;
            return state;
        },
        setAppsInternal: (state: SliceState, action: PayloadAction<IFdotInsightsApp[]>) => {
            state.apps = action.payload;
            return state;
        },
        updateIsLoadingInternal: (state: SliceState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            return state;
        },
        updateIsLoadingAppInsightsInternal: (state: SliceState, action: PayloadAction<boolean>) => {
            state.isLoadingAppInsights = action.payload;
            return state;
        },
        updateIsLoadingAppInsightsSummaryInternal: (state: SliceState, action: PayloadAction<boolean>) => {
            state.isLoadingAppInsightsSummary = action.payload;
            return state;
        },
        removeAppInternal: (state: SliceState, action: PayloadAction<string>) => {
            state.apps = state.apps.filter(i => i._id !== action.payload);
            return state;
        },
        updateNavigateBackToManagePage: (state: SliceState, action: PayloadAction<boolean>) => {
            state.navigateBackToManagePage = action.payload;
            state.noBack = false;
            return state;
        },
    }
});

export const { updateNavigateBackToManagePage } = appSlice.actions;
export default appSlice.reducer;

export const addApp = (
    name: string,
    successCallback: (app: IFdotInsightsApp) => void,
    failureCallback: () => void) => async (dispatch: any) => {
        dispatch(appSlice.actions.updateIsLoadingInternal(true));
        appApi.addApp(name)
            .then(
                (app: IFdotInsightsApp) => {
                    dispatch(appSlice.actions.addNewAppInternal(app));
                    dispatch(appSlice.actions.updateIsLoadingInternal(false));
                    successCallback(app);
                })
            .catch((error: any) => {
                console.log(error);
                dispatch(appSlice.actions.updateIsLoadingInternal(false));
                failureCallback();
            });
    };

export const updateApp = (
    app: IFdotInsightsApp,
    successCallback: (app: IFdotInsightsApp) => void,
    failureCallback: () => void) => async (dispatch: any) => {
        dispatch(appSlice.actions.updateIsLoadingInternal(true));
        appApi.updateApp(app)
            .then(
                (application: IFdotInsightsApp) => {
                    dispatch(appSlice.actions.updateAppInternal(application));
                    dispatch(appSlice.actions.updateIsLoadingInternal(false));
                    successCallback(application);
                })
            .catch((error: any) => {
                console.log(error);
                dispatch(appSlice.actions.updateIsLoadingInternal(false));
                failureCallback();
            });
    };

export const fetchApps = () => async (dispatch: any) => {
    dispatch(appSlice.actions.updateIsLoadingInternal(true));
    appApi.fetchApps()
        .then(
            (apps: IFdotInsightsApp[]) => {
                dispatch(appSlice.actions.setAppsInternal(apps));
                dispatch(appSlice.actions.updateIsLoadingInternal(false));
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(appSlice.actions.updateIsLoadingInternal(false));
        });
};

export const fetchApp = (id: string) => async (dispatch: any) => {
    dispatch(appSlice.actions.updateIsLoadingInternal(true));
    dispatch(appSlice.actions.setSelectedAppInternal(null));
    appApi.fetchApp(id)
        .then(
            (app: IFdotInsightsApp | null) => {
                dispatch(appSlice.actions.setSelectedAppInternal(app));
                if (app !== null) {
                    dispatch(appSlice.actions.addNewAppInternal(app));
                }
                dispatch(appSlice.actions.updateIsLoadingInternal(false));
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(appSlice.actions.updateIsLoadingInternal(false));
        });
};

export const deleteApp = (id: string) => async (dispatch: any) => {
    dispatch(appSlice.actions.updateIsLoadingInternal(true));
    appApi.deleteApp(id)
        .then(
            (successful: boolean) => {
                if (successful) {
                    dispatch(appSlice.actions.removeAppInternal(id));
                    dispatch(appSlice.actions.updateIsLoadingInternal(false));
                }
                else {
                    dispatch(appSlice.actions.updateIsLoadingInternal(false));
                }
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(appSlice.actions.updateIsLoadingInternal(false));
        });
}

export const addAppInsight = (appId: string, appInsight: IApplicationInsight) => async (dispatch: any) => {
    dispatch(appSlice.actions.updateIsLoadingInternal(true));
    appApi.addAppInsight(appId, appInsight)
        .then(
            (updatedAppInsight: IFdotInsightsApp) => {
                dispatch(appSlice.actions.updateAppInternal(updatedAppInsight));
                dispatch(appSlice.actions.updateIsLoadingInternal(false));
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(appSlice.actions.updateIsLoadingInternal(false));
        });
}

export const updateAppInsight = (
    appId: string,
    appInsight: IApplicationInsight,
    successCallback: () => void,
    failureCallback: () => void) => async (dispatch: any) => {

        dispatch(appSlice.actions.updateIsLoadingInternal(true));
        appApi.updateAppInsight(appId, appInsight)
            .then(
                (app: IFdotInsightsApp) => {
                    dispatch(appSlice.actions.updateAppInternal(app));
                    dispatch(appSlice.actions.updateIsLoadingInternal(false));
                    successCallback();
                })
            .catch((error: any) => {
                console.log(error);
                dispatch(appSlice.actions.updateIsLoadingInternal(false));
                failureCallback();
            });
    }

export const deleteAppInsight = (
    appId: string,
    appInsightId: string,
    successCallback: () => void,
    failureCallback: () => void) => async (dispatch: any) => {

        dispatch(appSlice.actions.updateIsLoadingInternal(true));
        appApi.deleteAppInsight(appId, appInsightId)
            .then(
                (app: IFdotInsightsApp) => {
                    dispatch(appSlice.actions.updateAppInternal(app));
                    dispatch(appSlice.actions.updateIsLoadingInternal(false));
                    successCallback();
                })
            .catch((error: any) => {
                console.log(error);
                dispatch(appSlice.actions.updateIsLoadingInternal(false));
                failureCallback();
            });
    }

export const fetchAppInsightData = (appId: string, appInsightAppId: string) => async (dispatch: any) => {
    dispatch(appSlice.actions.updateIsLoadingAppInsightsInternal(true));
    appApi.getDataFromAppInsights(appId, appInsightAppId)
        .then(
            (data: any) => {
                dispatch(appSlice.actions.updateAppInsightDataInternal(data));
                dispatch(appSlice.actions.updateIsLoadingAppInsightsInternal(false));
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(appSlice.actions.updateAppInsightDataInternal(null));
            dispatch(appSlice.actions.updateIsLoadingAppInsightsInternal(false));
        });
}

export const fetchAppInsightSummaryData = (appId: string, appInsightAppId: string) => async (dispatch: any) => {
    dispatch(appSlice.actions.updateIsLoadingAppInsightsSummaryInternal(true));
    appApi.getSummaryDataFromAppInsights(appId, appInsightAppId)
        .then(
            (data: any) => {
                dispatch(appSlice.actions.updateAppInsightSummaryDataInternal(data));
                dispatch(appSlice.actions.updateIsLoadingAppInsightsSummaryInternal(false));
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(appSlice.actions.updateAppInsightSummaryDataInternal(null));
            dispatch(appSlice.actions.updateIsLoadingAppInsightsSummaryInternal(false));
        });
}

export const getAppById = (state: SliceState, id: string | undefined): IFdotInsightsApp | undefined => {
    return id ? state.apps.find(i => i._id === id) : undefined;
}
