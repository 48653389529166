"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWithRetry = void 0;
const TimerUtils_1 = require("./TimerUtils");
const getWithRetry = (getMethod, baseWaitRetryTimeInMs, waitRetryTimeVarianceInMs, numberOfTimesToRetry) => __awaiter(void 0, void 0, void 0, function* () {
    let retries = 0;
    do {
        if (retries !== 0) {
            console.log(`Attempting axios call. Number of retries: ${retries} `);
        }
        try {
            const response = yield getMethod();
            if (response.status !== 429) {
                return response.data;
            }
            // Too many requests in a short time
            // Sleep prescribed time
            yield (0, TimerUtils_1.sleep)(baseWaitRetryTimeInMs + Math.random() * waitRetryTimeVarianceInMs);
            if (retries > numberOfTimesToRetry) {
                throw new Error(`Giving up. Tried axios call: ${retries} times!`);
            }
            retries++;
        }
        catch (err) {
            if (err.response.status !== 429) {
                throw err;
            }
            if (retries > numberOfTimesToRetry) {
                throw new Error(`Giving up. Tried axios call: ${retries} times!`);
            }
            retries++;
        }
    } while (true);
});
exports.getWithRetry = getWithRetry;
