import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IException,
    IExceptionDetailWithCount,
    IExceptionInstancesWithCount,
    IExceptionDetailResult,
    IExceptionInstanceResult
} from 'fdot-insights-shared';
import * as exceptionApi from './../../apis/ExceptionApi';

type SliceState = {
    exception: IException | null;
    exceptionDetails: IExceptionDetailResult[] | null;
    exceptionDetailCount: number;
    exceptionInstances: IExceptionInstanceResult[] | null;
    exceptionDetailPageIndex: number;
    totalCountOfExceptionInstances: number;
    exceptionInstancePageIndex: number;
    selectedExceptionId: string | null;
    selectedExceptionDetailId: string | null;
    loadingExceptionDetails: boolean;
    loadingExceptionInstances: boolean;
}

const exceptionSlice = createSlice({
    name: 'exception',
    initialState: {
        exception: null,
        exceptionDetails: null,
        exceptionDetailCount: 0,
        exceptionInstances: null,
        exceptionDetailPageIndex: 0,
        exceptionInstancePageIndex: 0,
        totalCountOfExceptionInstances: 0,
        selectedExceptionId: null,
        selectedExceptionDetailId: null,
        loadingExceptionDetails: false,
        loadingExceptionInstances: false
    } as SliceState,
    reducers: {
        updateException: (state: SliceState, action: PayloadAction<IException | null>) => {
            state.exception = action.payload;
            return state;
        },
        updateExceptionDetails: (state: SliceState, action: PayloadAction<IExceptionDetailResult[] | null>) => {
            state.exceptionDetails = action.payload;
            return state;
        },
        updateExceptionDetailsCount: (state: SliceState, action: PayloadAction<number>) => {
            state.exceptionDetailCount = action.payload;
            return state;
        },
        updateExceptionInstances: (state: SliceState, action: PayloadAction<IExceptionInstanceResult[] | null>) => {
            state.exceptionInstances = action.payload;
            return state;
        },
        updateExceptionDetailPageIndex: (state: SliceState, action: PayloadAction<number>) => {
            state.exceptionDetailPageIndex = action.payload;
            return state;
        },
        updateExceptionInstancePageIndex: (state: SliceState, action: PayloadAction<number>) => {
            state.exceptionInstancePageIndex = action.payload;
            return state;
        },
        updateTotalCountOfInstances: (state: SliceState, action: PayloadAction<number>) => {
            state.totalCountOfExceptionInstances = action.payload;
            return state;
        },
        updateSelectedExceptionId: (state: SliceState, action: PayloadAction<string | null>) => {
            state.selectedExceptionId = action.payload;
            return state;
        },
        updateSelectedExceptionDetailId: (state: SliceState, action: PayloadAction<string | null>) => {
            state.selectedExceptionDetailId = action.payload;
            return state;
        },
        updateLoadingExceptionDetails: (state: SliceState, action: PayloadAction<boolean>) => {
            state.loadingExceptionDetails = action.payload;
            return state;
        },
        updateLoadingExceptionInstances: (state: SliceState, action: PayloadAction<boolean>) => {
            state.loadingExceptionInstances = action.payload;
            return state;
        },
    }
});

export const {
    updateException,
    updateExceptionDetails,
    updateExceptionDetailsCount,
    updateExceptionInstances,
    updateExceptionDetailPageIndex,
    updateExceptionInstancePageIndex,
    updateTotalCountOfInstances,
    updateSelectedExceptionId,
    updateSelectedExceptionDetailId,
    updateLoadingExceptionDetails,
    updateLoadingExceptionInstances
} = exceptionSlice.actions;
export default exceptionSlice.reducer;

export const fetchException = (
    id: string,
    successCallback?: () => void,
    failCallback?: () => void) => async (dispatch: any) => {
        exceptionApi.getException(id)
            .then(
                (exception: IException) => {
                    dispatch(updateException(exception));
                    if (successCallback) {
                        successCallback();
                    }
                })
            .catch((error: any) => {
                console.log(error);
                if (failCallback) {
                    failCallback();
                }
            });
    }

export const fetchExceptionDetails = (
    exceptionId: string,
    page: number,
    includeCount: boolean,
    successCallback?: () => void,
    failCallback?: () => void) => async (dispatch: any) => {
        dispatch(updateLoadingExceptionDetails(true));
        exceptionApi.getExceptionDetails(exceptionId, page, includeCount)
            .then(
                (exceptionDetails: IExceptionDetailWithCount) => {
                    dispatch(updateExceptionDetails(exceptionDetails.results));
                    dispatch(updateLoadingExceptionDetails(false));
                    if (exceptionDetails.totalCount) {
                        dispatch(updateExceptionDetailsCount(exceptionDetails.totalCount));
                    }
                    if (successCallback) {
                        successCallback();
                    }
                })
            .catch((error: any) => {
                dispatch(updateLoadingExceptionDetails(false));
                console.log(error);
                if (failCallback) {
                    failCallback();
                }
            });
    }

export const fetchExceptionInstances = (
    id: string,
    page: number,
    includeCount: boolean,
    successCallback?: () => void,
    failCallback?: () => void) => async (dispatch: any) => {
        dispatch(updateLoadingExceptionInstances(true));
        exceptionApi.getExceptionInstances(id, page, includeCount)
            .then(
                (instancesWithCount: IExceptionInstancesWithCount) => {
                    dispatch(updateExceptionInstances(instancesWithCount.results));
                    dispatch(updateLoadingExceptionInstances(false));
                    if (instancesWithCount.occurrences) {
                        dispatch(updateTotalCountOfInstances(instancesWithCount.occurrences))
                    }
                    if (successCallback) {
                        successCallback();
                    }
                })
            .catch((error: any) => {
                dispatch(updateLoadingExceptionInstances(false));
                console.log(error);
                if (failCallback) {
                    failCallback();
                }
            });
    }

export const exceptionDetailsLoaded = (state: SliceState): boolean => {
    return state.exceptionDetails !== undefined;
}
