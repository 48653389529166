import * as notificationApi from '../../apis/NotificationApi';
import * as searchApi from '../../apis/SearchApi';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApplication, INotificationSearchCriteria, INotificationSearchResult, Level } from 'fdot-insights-shared';
import { IApplicationDecoded } from '../../models/IApplicationDecoded';
import { staffService } from "@fdot/arculus-staff-service";
import { logException } from 'fdot-insights-shared/services/LogService';
type SliceState = {
    isLoading: boolean,
    hasFilterChanged: boolean;
    levels: Level[],
    onlyApplicationsWithEmailNotSet: boolean,
    onlyApplicationsWithErrorsInLast6Months: boolean,
    partialApplicationName: string,
    pageIndex: number,
    totalItems: number,
    searchResults: IApplicationDecoded[];
}

const exceptionNotificationSlice = createSlice({
    name: 'exception-notification',
    initialState: {
        isLoading: false,
        hasFilterChanged: false,
        levels: [Level.UnitTest, Level.SystemTest, Level.Production],
        onlyApplicationsWithEmailNotSet: false,
        onlyApplicationsWithErrorsInLast6Months: true,
        partialApplicationName: '',
        pageIndex: 0,
        totalItems: 0,
        searchResults: []
    } as SliceState,
    reducers: {
        updateLevels: (state: SliceState, action: PayloadAction<Level[]>) => {
            state.levels = action.payload;
            state.hasFilterChanged = true;
            return state;
        },
        updateOnlyApplicationsWithEmailNotSent: (state: SliceState, action: PayloadAction<boolean>) => {
            state.onlyApplicationsWithEmailNotSet = action.payload;
            state.hasFilterChanged = true;
            return state;
        },
        updateOnlyApplicationsWithErrorsInLast6Months: (state: SliceState, action: PayloadAction<boolean>) => {
            state.onlyApplicationsWithErrorsInLast6Months = action.payload;
            state.hasFilterChanged = true;
            return state;
        },
        updatePartialApplicationName: (state: SliceState, action: PayloadAction<string>) => {
            state.partialApplicationName = action.payload;
            state.hasFilterChanged = true;
            return state;
        },
        updatePageIndex: (state: SliceState, action: PayloadAction<number>) => {
            state.pageIndex = action.payload;
            return state;
        },
        applyFilter: (state: SliceState) => {
            state.hasFilterChanged = false;
            state.isLoading = true;
            return state;
        },
        updateSearchResults: (state: SliceState, action: PayloadAction<{ results: IApplicationDecoded[], totalCount?: number }>) => {
            state.searchResults = action.payload.results;
            if (action.payload.totalCount !== undefined) {
                state.totalItems = action.payload.totalCount;
            }
            state.isLoading = false;
            return state;
        },
        updateApplication: (state: SliceState, action: PayloadAction<IApplicationDecoded>) => {
            for (let index = 0; index < state.searchResults.length; index++) {
                const item = state.searchResults[index];
                if (item._id === action.payload._id) {
                    state.searchResults[index] = action.payload;
                    break;
                }
            }
            return state;
        },
    }
});

export const {
    updateLevels,
    applyFilter,
    updateOnlyApplicationsWithEmailNotSent,
    updateOnlyApplicationsWithErrorsInLast6Months,
    updatePartialApplicationName,
    updatePageIndex,
    updateSearchResults,
    updateApplication
} = exceptionNotificationSlice.actions;
export default exceptionNotificationSlice.reducer;

export const search = (
    searchCriteria: INotificationSearchCriteria,
    successCallback?: () => void,
    failCallback?: () => void) => async (dispatch: any) => {

        const catchCode = (error: any) => {
            console.log(error);
            dispatch(updateSearchResults({ results: [], totalCount: 0 }));
            if (failCallback) {
                failCallback();
            }
        }

        dispatch(applyFilter());
        notificationApi.search(searchCriteria)
            .then((data: INotificationSearchResult) => {
                decodeUsers(data.results)
                    .then(decoded => {
                        const payload: { results: IApplicationDecoded[], totalCount?: number } = {
                            results: decoded,
                            totalCount: data.totalCount
                        };
                        dispatch(updateSearchResults(payload));
                        if (successCallback) {
                            successCallback();
                        }
                    })
                    .catch(error => {
                        catchCode(error);
                    });
            })
            .catch((error: any) => {
                catchCode(error)
            });
    };

export const update = (
    application: IApplication,
    successCallback?: () => void,
    failCallback?: () => void) => async (dispatch: any) => {

        const catchCode = (error: any) => {
            console.log(error);
            if (failCallback) {
                failCallback();
            }
        }

        notificationApi.update(application)
            .then((newApplication: IApplication) => {
                decodeUsers([newApplication])
                    .then(decoded => {
                        dispatch(updateApplication(decoded[0]));
                        if (successCallback) {
                            successCallback();
                        }
                    })
                    .catch(error => {
                        catchCode(error);
                    });
            })
            .catch((error: any) => {
                catchCode(error)
            });
    };

const decodeUsers = async (applications: IApplication[]): Promise<IApplicationDecoded[]> => {
    const start = new Date();

    const srsIds = applications.map(i => i.LastUpdateUserId).filter(i => i !== null && i !== undefined);
    const uniqueSrsIds: number[] = Array.from(new Set(srsIds));

    if (uniqueSrsIds.length === 0) {
        return applications as IApplicationDecoded[];
    }
    try {
        const users = await staffService.getStaffBySrsIds(uniqueSrsIds);
        const decodedList: IApplicationDecoded[] = [];
        for (const app of applications) {
            const decodedItem = app as IApplicationDecoded;
            const match = users.find(i => i.id === app.LastUpdateUserId);
            if (match) {
                decodedItem.LastUpdatedUserName = `${match.firstName} ${match.lastName}`;
            }
            decodedList.push(decodedItem);
        }
        const end = new Date();
        const duration = end.getTime() - start.getTime();
        console.log(`Get staff to decode user IDs took: ${duration} ms`);

        return decodedList;
    }
    catch (error) {
        logException(error);
        return applications as IApplicationDecoded[];
    }
}

export const createSearchCriteria = (state: SliceState, includeCount: boolean): INotificationSearchCriteria => {
    return {
        filterCriteria: {
            Levels: state.levels,
            OnlyApplicationsWithEmailNotSet: state.onlyApplicationsWithEmailNotSet,
            OnlyApplicationsWithErrorsInLast6Months: state.onlyApplicationsWithErrorsInLast6Months,
            PartialApplicationName: state.partialApplicationName
        },
        pageIndex: state.pageIndex,
        includeCountInResults: includeCount
    };
};

export const setApplication = (
    applicationId: string,
    successCallback?: () => void) => async (dispatch: any) => {
        searchApi.getApplicationById(applicationId)
            .then((newApplication: IApplication | null) => {
                dispatch(updatePartialApplicationName(newApplication?.Name || ''));
                if (successCallback) {
                    successCallback();
                }
            })
            .catch((error: any) => {
                logException(error);
            });
    };
