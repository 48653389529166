"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstNameLastName = exports.getUserInitials = exports.staffMap = void 0;
const title_case_1 = require("title-case");
const arculus_staff_service_1 = require("@fdot/arculus-staff-service");
const staffMap = (staff) => {
    return {
        srsId: staff.id,
        emailAddress: staff.emailAddress,
        azureAdOid: staff.azureAdOid,
        firstName: staff.firstName,
        lastName: staff.lastName,
        racfId: staff.racfId,
        phoneNumber: arculus_staff_service_1.phoneNumberUtils.formatPhoneNumber(staff.phone, staff.phoneExtension),
        positionWorkTitle: staff.activeDirectoryWorkingTitle !== undefined && staff.activeDirectoryWorkingTitle !== null
            ? (0, title_case_1.titleCase)(staff.activeDirectoryWorkingTitle)
            : "",
    };
};
exports.staffMap = staffMap;
const getUserInitials = (user) => {
    if (user === null || user === undefined) {
        return "?";
    }
    let initials = "";
    if (user.firstName && user.firstName.length > 0) {
        initials += user.firstName[0];
    }
    if (user.lastName && user.lastName.length > 0) {
        initials += user.lastName[0];
    }
    return initials;
};
exports.getUserInitials = getUserInitials;
const getFirstNameLastName = (user) => {
    if (user === undefined || user === null) {
        return "";
    }
    return `${user.firstName} ${user.lastName}`;
};
exports.getFirstNameLastName = getFirstNameLastName;
