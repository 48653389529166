import * as configApi from '../../apis/ConfigApi';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppConfig, IConfig } from 'fdot-insights-shared';

type SliceState = {
    loadingConfig: boolean;
    appConfiguration: IAppConfig | null;
}

const configSlice = createSlice({
    name: 'app-config',
    initialState: {
        loadingConfig: false,
        appConfiguration: null
    } as SliceState,
    reducers: {
        updateAppConfig: (state: SliceState, action: PayloadAction<IAppConfig>) => {
            state.appConfiguration = {
                APPINSIGHTS_INSTRUMENTATIONKEY: action.payload.APPINSIGHTS_INSTRUMENTATIONKEY,
                ARCULUS_API_KEY: action.payload.ARCULUS_API_KEY,
                ARCULUS_API_BASE_URL: action.payload.ARCULUS_API_BASE_URL,
                ENVIRONMENT: action.payload.ENVIRONMENT,
                config: action.payload.config
            }
            return state;
        },
        loadingStarted: (state: SliceState) => {
            state.loadingConfig = true;
            return state;
        },
        loadingCompleted: (state: SliceState) => {
            state.loadingConfig = false;
            return state;
        }
    }
});

export const { updateAppConfig, loadingStarted, loadingCompleted } = configSlice.actions;
export default configSlice.reducer;

export const fetchConfig = () => async (dispatch: any) => {
    dispatch(loadingStarted());
    configApi.getConfig()
        .then(
            (appConfig: IAppConfig) => {
                dispatch(updateAppConfig(appConfig));
                dispatch(loadingCompleted());
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(loadingCompleted())
        });
}

export const updateConfig = (config: IConfig, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    configApi.updateConfig(config)
        .then(
            (appConfig: IAppConfig) => {
                dispatch(updateAppConfig(appConfig));
                successCallback()
            })
        .catch((error: any) => {
            console.log(error);
            failureCallback();
        });
}