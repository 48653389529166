import axios from 'axios';
import { IApplication, INotificationSearchCriteria, INotificationSearchResult } from 'fdot-insights-shared';

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/exception/notifications`;

export const search = async (searchCriteria: INotificationSearchCriteria): Promise<INotificationSearchResult> => {
    const response = await axios.post(`${baseUrl}/search`, searchCriteria);
    return response.data;
}

export const update = async (application: IApplication): Promise<IApplication> => {
    const response = await axios.post(`${baseUrl}/${application._id}`, application);
    return response.data;
}