import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IException } from 'fdot-insights-shared';
import { v4 as uuidv4 } from 'uuid';
import { IUniqueException } from "../../models/IUniqueException";

type SliceState = {

    liveTail: Array<IUniqueException>;
    itemsToShow: number;
}

const liveTailSlice = createSlice({
    name: 'live-tail',
    initialState: {
        liveTail: [],
        itemsToShow: 25,
    } as SliceState,
    reducers: {
        add: (state: SliceState, action: PayloadAction<IException>) => {
            const uniqueException: IUniqueException = {
                ...action.payload,
                uniqueId: uuidv4()
            } as IUniqueException;
            state.liveTail.unshift(uniqueException);
            state.liveTail = state.liveTail.slice(0, state.itemsToShow);
            return state;
        },
        clear: (state: SliceState) => {
            state.liveTail = [];
            return state;
        },
        updateItemsToShow: (state: SliceState, action: PayloadAction<number>) => {
            state.itemsToShow = action.payload;
            if (state.liveTail.length > action.payload) {
                state.liveTail = state.liveTail.slice(0, action.payload);
            }
            return state;
        },
    }
});

export const { add, clear, updateItemsToShow } = liveTailSlice.actions;
export default liveTailSlice.reducer;
