import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css"
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './redux/Store';

const render = () => {
    const App = require('./App').default

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    )
}

render();

if (fdot.process.env.APP_ENVIRONMENT === 'development' && (module as any).hot) {
    (module as any).hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
