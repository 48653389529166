import axios from 'axios';
import { IAppConfig, IConfig } from 'fdot-insights-shared';

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/config`;

export const getConfig = async (): Promise<IAppConfig> => {
    const response = await axios.get(baseUrl);
    return response.data;
};

export const updateConfig = async (config: IConfig): Promise<IAppConfig> => {
    const response = await axios.patch(baseUrl, config);
    return response.data;
}