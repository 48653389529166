import React, { Suspense } from "react";
import Loading from "react-loading";
import {
    Routes,
    Route
} from 'react-router-dom';

const LandingPage = React.lazy(() => import('../LandingPage'));
const NotAuthorized = React.lazy(() => import('../NotAuthorized'));
const LoginFailure = React.lazy(() => import('../LoginFailure'));
const NoMatch = React.lazy(() => import('../NoMatch'));

const AnonymousRoutes: React.FC = () => {

    return (
        <Suspense fallback={<Loading></Loading>}>
            <Routes>
                <Route path='/' element={<LandingPage />} />

                <Route path="/NotAuthorized" element={<NotAuthorized />} />
                <Route path="/LoginFailure" element={<LoginFailure />} />

                <Route path="*" element={<NoMatch />} />
            </Routes>
        </Suspense>
    );
}

export default AnonymousRoutes;
