"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPhoneNumber = void 0;
const LogService_1 = require("./../services/LogService");
const formatPhoneNumber = (phoneNumber, extension) => {
    if (phoneNumber === undefined) {
        return '';
    }
    if (phoneNumber.length === 10) {
        const formatted = `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6)}`;
        return extension !== undefined && extension.length > 0
            ? `${formatted} (${extension})`
            : formatted;
    }
    else if (phoneNumber.length === 7) {
        const formatted = `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 4)}`;
        return extension !== undefined && extension.length > 0
            ? `${formatted} (${extension})`
            : formatted;
    }
    else {
        (0, LogService_1.logException)(`Implement formatPhoneNumber logic for: ${phoneNumber}`);
        return phoneNumber;
    }
};
exports.formatPhoneNumber = formatPhoneNumber;
