"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumToNumberArray = exports.enumToArray = void 0;
const enumToArray = (enumeration, initialEntry) => {
    const array = Object.keys(enumeration).map(k => enumeration[k]);
    if (initialEntry !== undefined) {
        array.unshift(initialEntry);
    }
    return array;
};
exports.enumToArray = enumToArray;
const enumToNumberArray = (enumeration, initialEntry) => {
    const enumValues = [];
    for (const value in enumeration) {
        if (typeof enumeration[value] === 'number') {
            enumValues.push(enumeration[value]);
        }
    }
    return enumValues;
};
exports.enumToNumberArray = enumToNumberArray;
