import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Level, IApplication, ISearchCriteria, ISearchResult, IExceptionResult } from 'fdot-insights-shared';
import * as searchApi from "./../../apis/SearchApi";

type SliceState = {
    levels: Level[];
    applications: IApplication[];
    users: string[];
    occurredOnOrAfter: Date | null;
    occurredBefore: Date | null;
    messageContains: string | null;
    stackTraceContains: string | null;
    pageIndex: number;
    totalItems: number;
    filterChanged: boolean;
    loadingResults: boolean;
    searchResults: IExceptionResult[];
}

const searchSlice = createSlice({
    name: 'filterCriteria',
    initialState: {
        levels: [Level.Production],
        applications: [],
        users: [],
        occurredOnOrAfter: null,
        occurredBefore: null,
        messageContains: null,
        stackTraceContains: null,
        pageIndex: 0,
        totalItems: 0,
        filterChanged: false,
        loadingResults: false,
        searchResults: []
    } as SliceState,
    reducers: {
        updateLevels: (state: SliceState, action: PayloadAction<Level[]>) => {
            state.levels = action.payload;
            state.filterChanged = true;
            return state;
        },
        updateApplications: (state: SliceState, action: PayloadAction<IApplication[]>) => {
            state.applications = action.payload;
            state.filterChanged = true;
            return state;
        },
        updateUsers: (state: SliceState, action: PayloadAction<string[]>) => {
            state.users = action.payload;
            state.filterChanged = true;
            return state;
        },
        updateOccurredOnOrAfter: (state: SliceState, action: PayloadAction<Date | null>) => {
            state.occurredOnOrAfter = action.payload;
            state.filterChanged = true;
            return state;
        },
        updateOccurredBefore: (state: SliceState, action: PayloadAction<Date | null>) => {
            state.occurredBefore = action.payload;
            state.filterChanged = true;
            return state;
        },
        updateMessage: (state: SliceState, action: PayloadAction<string | null>) => {
            state.messageContains = action.payload === null || action.payload.trim() === ""
                ? null
                : action.payload;
            state.filterChanged = true;
            return state;
        },
        updateStackTrace: (state: SliceState, action: PayloadAction<string | null>) => {
            state.stackTraceContains = action.payload === null || action.payload.trim() === ""
                ? null
                : action.payload;
            state.filterChanged = true;
            return state;
        },
        updatePageIndex: (state: SliceState, action: PayloadAction<number>) => {
            state.pageIndex = action.payload;
            return state;
        },
        applyFilter: (state: SliceState) => {
            state.filterChanged = false;
            state.loadingResults = true;
            return state;
        },
        updateSearchResults: (state: SliceState, action: PayloadAction<ISearchResult>) => {
            state.searchResults = action.payload.results;
            state.totalItems = action.payload.totalCount !== undefined
                ? action.payload.totalCount
                : state.totalItems;
            state.loadingResults = false;
            return state;
        }
    }
});

export const {
    updateLevels,
    updateApplications,
    updateUsers,
    updateOccurredOnOrAfter,
    updateOccurredBefore,
    updateMessage,
    updateStackTrace,
    updatePageIndex,
    applyFilter,
    updateSearchResults,
} = searchSlice.actions;

export default searchSlice.reducer;

export const search = (
    searchCriteria: ISearchCriteria,
    successCallback?: () => void,
    failCallback?: () => void) => async (dispatch: any) => {
        dispatch(applyFilter());
        searchApi.search(searchCriteria)
            .then((data: ISearchResult) => {
                dispatch(updateSearchResults(data));
                if (successCallback) {
                    successCallback();
                }
            })
            .catch((error: any) => {
                console.log(error);
                dispatch(updateSearchResults({ results: [], totalCount: 0 }));
                if (failCallback) {
                    failCallback();
                }
            });
    };

export const createSearchCriteria = (state: SliceState, includeCount: boolean): ISearchCriteria => {
    return {
        filterCriteria: {
            levels: state.levels,
            applications: state.applications,
            users: state.users,
            occurredOnOrAfter: state.occurredOnOrAfter,
            occurredBefore: state.occurredBefore,
            messageContains: state.messageContains,
            stackTraceContains: state.stackTraceContains
        },
        pageIndex: state.pageIndex,
        includeCountInResults: includeCount
    };
};

export const setApplication = (
    id: string,
    successCallback?: () => void,) => async (dispatch: any) => {
        searchApi.getApplicationById(id)
            .then((application: IApplication | null) => {
                dispatch(updateApplications(application === null ? [] : [application]));
                if (successCallback) {
                    successCallback();
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };
