import React, { useState, CSSProperties } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/RootReducer";
import { staffUtils } from "fdot-insights-shared";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PhotoCallout from "./common/persona/PhotoCallout";
import { Persona, PersonaSize } from "office-ui-fabric-react";

const Header = () => {
  const dropDownMenuItemStyle: CSSProperties = {
    color: "black",
  };

  const headerTextStyle: CSSProperties = {
    color: "white",
    padding: "10px",
  };

  const brandStyle: CSSProperties = {
    paddingRight: 20,
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [showCallout, setShowCallout] = useState<boolean>(false);
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );

  const toggle = () => setIsExpanded(!isExpanded);

  const getLoggedInUserDisplay = () => {
    if (user === undefined) {
      return null;
    }
    const nameOfStaff = staffUtils.getFirstNameLastName(user);
    const initials = staffUtils.getUserInitials(user);

    return isAuthenticated ? (
      <>
        <div className="d-none d-xl-block" style={headerTextStyle}>
          <Persona
            id="LoggedInUserPhoto"
            imageUrl={user.photoUri || undefined}
            imageAlt={`Profile Pic for: ${nameOfStaff}`}
            size={PersonaSize.size40}
            text={nameOfStaff}
            styles={{ primaryText: { color: "white" } }}
            imageInitials={initials}
            imageShouldFadeIn={true}
            onClick={() => setShowCallout(true)}
          ></Persona>
          {showCallout && (
            <PhotoCallout
              personaId="LoggedInUserPhoto"
              showOptions={true}
              staff={user}
              imageUrl={user.photoUri || undefined}
              nameOfStaff={nameOfStaff}
              imageInitials={initials}
              close={() => setShowCallout(false)}
            ></PhotoCallout>
          )}
        </div>
      </>
    ) : null;
  };

  const getManageOptions = () => {
    if (isAuthenticated) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Manage
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Applications"}
            >
              Manage Applications
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return null;
  };

  const getOnPremiseOptions = () => {
    if (isAuthenticated) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            On-Premise
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Exceptions</DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/LiveTail"}
            >
              Live Tail
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Search"}
            >
              Exception Search
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/ExceptionNotification"}
            >
              Exception Notifications
            </DropdownItem>
            <DropdownItem header>Availability</DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Availability/Tests"}
            >
              Availability Tests
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Availability"}
            >
              Availability Dashboard
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return null;
  };

  const getCloudOptions = () => {
    if (isAuthenticated) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Cloud
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Arculus API Metrics</DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Arculus/Usage"}
            >
              Usage
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Arculus/Error"}
            >
              Failures
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Arculus/Response"}
            >
              Average Response Time
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Arculus/Bandwidth"}
            >
              Total Bandwidth
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Arculus/Logs"}
            >
              Call Logs
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Arculus/Inventory"}
            >
              API Inventory
            </DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Arculus/Users"}
            >
              Users/Subscriptions
            </DropdownItem>
            <DropdownItem header>Application Insights</DropdownItem>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/AppInsights"}
            >
              Application Insights
            </DropdownItem>

            <DropdownItem header>Coming Soon</DropdownItem>
            <DropdownItem disabled>
              Cool New Cloud Features Coming Soon!
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return null;
  };

  const getTestEnvironmentOptions = () => {
    if (
      isAuthenticated &&
      (fdot.process.env.APP_ENVIRONMENT === "development" ||
        fdot.process.env.APP_ENVIRONMENT === "test")
    ) {
      return (
        <Nav navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Test Only Features
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                style={dropDownMenuItemStyle}
                tag={Link}
                to={"/Impersonate"}
              >
                Impersonation
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      );
    }
    return null;
  };

  const getNavbar = () => {
    return (
      <div>
        <Navbar color="dark" dark expand="md">
          <NavbarBrand href="/" style={brandStyle}>
            <span title="FDOT Insights">FDOT Insights</span>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isExpanded} navbar>
            {getTestEnvironmentOptions()}
            <Nav navbar>{getManageOptions()}</Nav>
            <Nav navbar>{getOnPremiseOptions()}</Nav>
            <Nav navbar>{getCloudOptions()}</Nav>
            <Nav className="ml-auto">
              <NavItem>{getLoggedInUserDisplay()}</NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  };

  return getNavbar();
};

export default Header;
