import axios from "axios";
import {
  IApiOperation,
  IApiSubscription,
  IArculusApi,
  IQueryParameters,
  ILogEntry,
  ILogQueryParameters,
} from "fdot-insights-shared";

const baseQueryUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/arculus`;

export const getUsers = async (): Promise<any> => {
  const response = await axios.get(`${baseQueryUrl}/users`);
  return response.data;
};

export const getSubscriptions = async (): Promise<IApiSubscription[]> => {
  const response = await axios.get(`${baseQueryUrl}/subscriptions`);
  return response.data;
};

export const getInventory = async (): Promise<IArculusApi[]> => {
  const response = await axios.get(`${baseQueryUrl}/inventory`);
  return response.data;
};

export const getApis = async (): Promise<IArculusApi[]> => {
  const response = await axios.get(`${baseQueryUrl}/apis`);
  return response.data;
};

export const getOperationsByApiId = async (
  apiId: string
): Promise<IApiOperation[]> => {
  const response = await axios.get(`${baseQueryUrl}/apis/${apiId}/operations`);
  return response.data;
};

export const getMetrics = async (
  queryParameters: IQueryParameters
): Promise<any> => {
  const response = await axios.post(`${baseQueryUrl}/metrics`, queryParameters);
  return response.data;
};

export const getLogs = async (
  queryParameters: ILogQueryParameters
): Promise<ILogEntry[]> => {
  const response = await axios.post(`${baseQueryUrl}/logs`, queryParameters);
  return response.data;
};
