import { combineReducers } from "@reduxjs/toolkit";
import configReducer from './slices/ConfigSlice';
import userReducer from './slices/UserSlice';
import searchReducer from './slices/SearchSlice';
import profileReducer from './slices/ProfileSlice';
import exceptionReducer from './slices/ExceptionSlice';
import liveTailReducer from './slices/LiveTailSlice';
import appReducer from './slices/AppSlice';
import arculusReducer from './slices/ArculusSlice';
import availabilityReducer from './slices/AvailabilitySlice';
import apiHistogramReducer from './slices/ApiHistogramSlice';
import exceptionNotificationReducer from './slices/ExceptionNotificationSlice';

const rootReducer = combineReducers({
    appReducer,
    arculusReducer,
    availabilityReducer,
    configReducer,
    exceptionNotificationReducer,
    exceptionReducer,
    liveTailReducer,
    profileReducer,
    searchReducer,
    apiHistogramReducer,
    userReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
